import request from 'oc-request';

export default new class SearchProductList {
    constructor() {
        this.sortingSelector = 'select[name="sorting"]';
        this.ajaxWrapper = '_ajax-search-product-list';
        this.requestData = {
            'page': 1,
            'sort': ''
        };
    }

    updateProductList(page = 1) {
        this.updateRequestString(page);

        request.sendData('ProductList::onAjaxRequest', {
            update: {
                'product/search-result/search-result': `.${this.ajaxWrapper}`
            },
            success: function (response) {
                this.success(response);
            }
        });
    }

    updateRequestData(page) {
        this.requestData = {
            'sort': document.querySelector(`${this.sortingSelector}`).value,
            'page': page
        };
    }

    updateRequestString(page) {
        this.updateRequestData(page);

        const arKeyList = Object.keys(this.requestData);
        let sResult = new URLSearchParams(location.search);
        let setPage = false;

        arKeyList.forEach((sKey) => {
            let sValue = this.requestData[sKey];
            if (Array.isArray(sValue)) {
                sValue = sValue.join('|');
            }

            if (sKey == 'page' && sValue == 1) {
                if (sResult.has('page')) {
                    sResult.delete('page');
                    setPage = false
                }
                return;
            }

            if (sResult.toString().length > 0) {
                if (sResult.has('page')) {
                    sResult.set('page', sValue);
                    setPage = true;
                } else {
                    sResult += '&';
                }
            }

            if (sKey != 'page' || !setPage) {
                sResult = new URLSearchParams(location.search);
                sResult.set(sKey, sValue);
            }

        });

        window.history.pushState(null, null, `${location.origin}${location.pathname}${sResult ? '?' + sResult : ''}`);
    }
}();

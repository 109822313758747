import Auth from '../auth/auth';
import request from 'oc-request';
export default new class CheckoutFormValidation {
    constructor() {
        this.alerta = '.alerta';
        this.form = '_ajax_create_order';
        this.cartItemItem = 'details__order--item';
        this.errorClass = 'bg-danger';
        this.buttonClass = '_create_order_button';
        this.wasValidatedClass = 'was-validated';

        this.eventHandlers();
    }

    eventHandlers() {
        if (!document.querySelectorAll(`.${this.form}`).length > 0) {
            return;
        }

        const _this = this;
        const forms = document.querySelectorAll(`.${_this.form}`);
        const button = document.querySelector(`.${_this.buttonClass}`);

        document.addEventListener('readystatechange', (e) => {
            if (e.target.readyState == 'complete') {
                // Fetch all the forms we want to apply custom Bootstrap validation styles to
                var forms = document.getElementsByClassName('needs-validation');
                // Loop over them and prevent submission
                var validation = Array.prototype.filter.call(forms, function(form) {
                    form.addEventListener('submit', function(event) {
                        event.preventDefault();
                        event.stopPropagation();
                        form.classList.add('was-validated');
                        if (form.checkValidity() === false) {
                            event.preventDefault();
                            event.stopPropagation();
                            setTimeout(() => {
                                var alerta = document.querySelector(`${_this.alerta}`);
                                alerta.classList.add('show');
                                alerta.innerText = document.querySelector(`.${_this.buttonClass}`).getAttribute('data-form-errors');

                                setTimeout(() => {
                                    alerta.classList.remove('show');
                                }, 1000)
                            }, 100)
                        }else{
                            event.preventDefault();
                            event.stopPropagation();
                            _this.updateCart()
                        }
                    }, false);
                });
            }
        }, false);
    }

    createOrder(preloader) {

        const form = document.querySelector(`.${this.form}`),
            button = document.querySelector(`.${this.buttonClass}`),
            shipping_disabled = document.querySelector('#shipping-form')?document.querySelector('#shipping-form').getAttribute('data-disabled'):true,
            payment_method_id = form.querySelector('input[name="payment_method_id"]:checked'),
            shipping_type_id = document.querySelector('input[name="shipping_type_id"]'),
            isShipping = document.querySelector('#shipping-list'),
            _this = this;

        let data = {
            'order': {
                'payment_method_id': payment_method_id?payment_method_id.value:null,
                'shipping_type_id': shipping_type_id?shipping_type_id.value:null,
            },
            'user': {
                'name': document.querySelector('input[name="name"]').value,
                'last_name': document.querySelector('input[name="last_name"]')?document.querySelector('input[name="last_name"]').value:null,
                'email': document.querySelector('input[name="email"]').value,
                'phone': document.querySelector('input[name="phone"]').value,
            },
            'shipping_address': {
                'address1': !shipping_disabled?form.querySelector('input[name="shipping_address1"]').value:null,
                'address2': !shipping_disabled?form.querySelector('input[name="shipping_address2"]').value:null,
                'country': function () {
                    if (!shipping_disabled && document.querySelector('#shipping-location')
                        .querySelector('select[name=country_id]') != null) {
                        return document.querySelector('#shipping-location')
                            .querySelector('select[name=country_id]')
                            .selectedOptions[0].text;
                    } else {
                        return null;
                    }
                },
                'state': function () {
                    if (!shipping_disabled && document.querySelector('#shipping-location')
                        .querySelector('select[name=state_id]') != null) {
                        return document.querySelector('#shipping-location')
                            .querySelector('select[name=state_id]')
                            .selectedOptions[0].text;
                    } else {
                        return null;
                    }
                },
                'postcode': !shipping_disabled?document.querySelector('#shipping-location')
                    .querySelector('input').value:null,
            },
            'billing_address': {
                'address1': form.querySelector('input[name="billing_address1"]').value,
                'address2': form.querySelector('input[name="billing_address2"]')?form.querySelector('input[name="billing_address2"]').value:null,
                'country': document.querySelector('#billing-location')
                    .querySelector('select[name=country_id]')
                    .selectedOptions[0].text,
                'state': document.querySelector('#billing-location')
                    .querySelector('select[name=state_id]')
                    .selectedOptions[0].text,
                'postcode': document.querySelector('#billing-location')
                    .querySelector('input')?document.querySelector('#billing-location')
                    .querySelector('input').value:null,
            }
        };

        //Si hay tipos de envio para seleccionar
        if (shipping_type_id != null) {
            this.clearNotAvailableCartPosition();
            request.sendData('MakeOrder::onCreate', {
                'data': data,
                success: function (response) {
                    document.querySelector(`.${_this.buttonClass}`).setAttribute('disabled', false)
                    if (!!response && (response.status || !!response['X_OCTOBER_REDIRECT'])) {
                        this.success(response);
                    } else {
                        button.setAttribute('data-content', response.message);
                        _this.markNotAvailableOfferPosition(response);
                        setTimeout(function () {
                            var alert = document.querySelector(`${_this.alerta}`);
                            alert.innerText = document.querySelector(`.${_this.buttonClass}`).getAttribute('data-error-gateway')
                            alert.classList.add('show');

                            setTimeout(() => {
                                alert.classList.remove('show');
                            }, 1000)
                        }, 100);
                    }
                },
                complete: function () {
                    preloader.classList.remove('is-active');
                }
            });
        }else{
            //Si no hay tipos de envio para sellecionar
            if(isShipping == null){
                this.clearNotAvailableCartPosition();
                request.sendData('MakeOrder::onCreate', {
                    'data': data,
                    success: function (response) {
                        document.querySelector(`.${_this.buttonClass}`).setAttribute('disabled', false)
                        if (!!response && (response.status || !!response['X_OCTOBER_REDIRECT'])) {
                            this.success(response);
                        } else {
                            button.setAttribute('data-content', response.message);
                            _this.markNotAvailableOfferPosition(response);
                            setTimeout(function () {
                                var alert = document.querySelector(`${_this.alerta}`);
                                alert.innerText = document.querySelector(`.${_this.buttonClass}`).getAttribute('data-error-gateway')
                                alert.classList.add('show');

                                setTimeout(() => {
                                    alert.classList.remove('show');
                                }, 1000)
                            }, 100);
                        }
                    },
                    complete: function () {
                        preloader.classList.remove('is-active');
                    }
                });
            }else{
                Auth.alertHandler('#shippingnotset');
                preloader.classList.remove('is-active');
            }
        }
    }

    markNotAvailableOfferPosition(response) {
        if (!response || !response.data || !response.data.cart_position_id) {
            return;
        }

        document.querySelector(`.${this.cartItemItem}[data-position-id="${response.data.cart_position_id}"]`).classList.add(this.errorClass);
    }

    clearNotAvailableCartPosition() {
        document.querySelector(`.${this.cartItemItem}`).classList.remove(this.errorClass);
    }

    updateCart() {
        const _this = this;
        let cart = [],
            data = [],
            errors = false,
            quantity = 0,
            shipping_type_id = document.querySelector('input[name=shipping_type_id]'),
            maxQuantity = 0;
            const preloader = document.querySelector('.loader-container');

        if (preloader) {
            preloader.classList.add('is-active');
        }

        if (shipping_type_id){
        	data.push(['shipping_type_id', shipping_type_id.value]);
        }

        // Item list row
        let cart_list = document.querySelector('.details__order-list').childNodes;

        cart_list.forEach(function (item) {

            let properties = item.querySelectorAll('select[data-target=property_slim]');
            let temp = [];
            let offer_id = ['id', item.getAttribute('data-position-id')];
            quantity = ['quantity', item.querySelector('input[name=quantity]').value];
            maxQuantity = parseInt(item.querySelector('input[name=quantity]').getAttribute('max'));

            if (quantity > maxQuantity) {
                item.classList.add('border border-danger');
                errors = true;
            } else {
                if (item.classList.contains('border border-danger')) {
                    item.classList.remove('border border-danger')
                }
            }

            properties.forEach(function (prop) {
                temp.push([prop.getAttribute('name').toLowerCase(), prop.slim.selected()])
            })

            let object = Object.fromEntries([offer_id, quantity, ['property', Object.fromEntries(temp)]]);
            cart.push(object);
        })

        data.push(['cart', cart]);
        let object = Object.fromEntries(data);

        if (errors == false) {
            request.sendData('Cart::onUpdate', {
                data: object,
                success: function (response) {
                    if (!!response && response.status && !!response.data) {;
                        _this.createOrder(preloader);
                    }
                }
            });
        }
    }
}();

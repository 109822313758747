import CartLinkHeader from '../cart-link-header/cart-link-header';
import Auth from '../auth/auth';
import request from 'oc-request';

export default new class ButtonAddToCart {
    constructor() {
        //Init selectors
        this.btnAddToCart = 'btn-add-to-cart';
        this.fastAddToCart = 'fast-add-to-cart';
        //Add events
        this.eventHandlers();
    }

    eventHandlers() {
        document.addEventListener('click', (e) => {
            if (e.target.closest(`.${this.btnAddToCart}`) && e.target.closest(`.${this.btnAddToCart}`).hasAttribute('disabled') == false){

                const   button = e.target.closest(`.${this.btnAddToCart}`),
                        event = button.getAttribute('data-event'),
                        form = button.parentNode.parentNode;

                button.setAttribute('disabled', true);

                if (event == 'add') {
                    this.addOfferToCart(form, button);
                } else {
                    if (event == 'remove') {
                        this.removeProductFromCart(form, button);
                    }
                }
            }
        });

        //fast add to cart
        document.addEventListener('click', (e) => {
            if (e.target.closest(`.${this.fastAddToCart}`) && e.target.closest(`.${this.fastAddToCart}`).hasAttribute('disabled') == false){

                const   button = e.target.closest(`.${this.fastAddToCart}`),
                        event = button.getAttribute('data-event'),
                        form = button.parentNode;

                button.setAttribute('disabled', true);

                if (event == 'add') {
                    this.addOfferToCart(form, button);
                } else {
                    if (event == 'remove') {
                        this.removeProductFromCart(form, button);
                    }
                }
            }
        });
    }

    addOfferToCart(form, button) {
        const _this = this
        const cartData = [], temp = [], property = [];
        var offerEmpty = false;
        const preloader = document.querySelectorAll(`div[data-loader="spinner"]`);
        if (preloader){
            // button.querySelector('p').classList.add('d-none');
            document.querySelectorAll('[data-loader="spinner"]').forEach( el => {
                el.classList.remove('d-none');
            })
        }

        form.querySelectorAll(`input[data-target='true']`).forEach((el, index) => {
            const name = el.getAttribute('name');
            if (!el.hasAttribute('data-type')) {
                if (el.value == '')
                    offerEmpty = true;
                temp.push([name, el.value])
            } else {
                property.push([name, el.value]);
            }
        })

        if (!offerEmpty) {
            let propertyObject = Object.fromEntries(property);
            temp.push(['property', propertyObject]);
            let object = Object.fromEntries(temp);
            cartData.push(object);
            button.setAttribute('data-event', 'remove');

            request.sendData('Cart::onAdd', {
                data: {'cart': cartData},
                success: (response) => {
                    if (!!response && response.status) {
                        CartLinkHeader.updateBlock();
                        let fastButton = document.querySelectorAll(`.fast-add-to-cart[data-product-id="${ button.getAttribute('data-product-id') }"]`);
                        if (fastButton.length > 0) {
                            fastButton.forEach(btn => {
                                btn.setAttribute('data-event', 'remove')
                                btn.querySelector('p').innerHTML = ``;
                            })
                        }else{
                            var text = button.getAttribute('data-remove-text')?button.getAttribute('data-remove-text'):'';
                            button.querySelector('p').innerHTML = `${text}`;
                        }
                        Auth.alertHandler('#itemAdded');
                        _this.updateThisBlock( button, preloader );
                    } else {
                        button.setAttribute('data-content', response.message);
                    }
                }
            });
        }else {
            Auth.alertHandler('#nonSizeSlected');
            preloader.classList.add('d-none');
            button.removeAttribute('disabled');
        }

    }

    removeProductFromCart(form, button) {
        const _this = this;
        const cartData = [];
        const temp = [];
        const property = [];
        let object = null;
        const preloader = document.querySelector(`div[data-loader="spinner"]`);

        if (preloader){
            // button.querySelector('p').classList.add('d-none');
            document.querySelectorAll('[data-loader="spinner"]').forEach( el => {
                el.classList.remove('d-none');
            })
            button.setAttribute('data-event', 'add');
        }

        form.querySelectorAll(`input[data-target='true']`).forEach((el, index) => {
            const name = el.getAttribute('name');
            if (!el.hasAttribute('data-type')) {
                temp.push([name, el.value])
            } else {
                property.push([name, el.value]);
            }
        })
        let propertyObject = Object.fromEntries(property);
        temp.push(['property', propertyObject]);
        object = Object.fromEntries(temp);
        cartData.push(object);

        request.sendData('Cart::onRemove', {
            data: {'cart': cartData},
            success: (response) => {
                if (!!response && response.status) {
                    CartLinkHeader.updateBlock();
                        let fastButton = document.querySelectorAll(`.fast-add-to-cart[data-product-id="${ button.getAttribute('data-product-id') }"]`);
                        if (fastButton.length > 0) {
                            fastButton.forEach(btn => {
                                btn.setAttribute('data-event', 'remove')
                                btn.querySelector('p').innerHTML = ``;
                            })
                        }else{
                            var text = button.getAttribute('data-add-text')?button.getAttribute('data-add-text'):'';
                            button.querySelector('p').innerHTML = `${text}`;
                        }
                    Auth.alertHandler('#itemRemoved');
                    _this.updateThisBlock( button, preloader );
                } else {
                    button.getAttribute('data-content', response.message);
                }
            }
        });
    }

    updateThisBlock(button, preloader) {
        const _this = this;
        let addto = document.querySelector(`.${_this.btnAddToCart}`);
        let fast = document.querySelector(`.${_this.fastAddToCart}`);
        if (addto)
            document.querySelector(`.${_this.btnAddToCart}`).removeAttribute('disabled');

        if (fast)
            document.querySelector(`.${_this.fastAddToCart}`).removeAttribute('disabled');
        return  button.querySelector('p').classList.add('d-block'),
                setTimeout(() => {
                    document.querySelectorAll('[data-loader="spinner"]').forEach( el => {
                        el.classList.add('d-none');
                    })
                }, 1000),
                button.removeAttribute('disabled');
    }
}();
